import { cva, type VariantProps } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { cn } from '@/lib/utils';

export const statusTagVariants = cva(
  'inline-flex items-center rounded-full text-sm py-1 px-3 truncate transition-all',
  {
    variants: {
      variant: {
        primary: 'border-transparent bg-primary-400 text-primary-10',
        'outline-warn': 'border-transparent bg-warn-100 text-warn-500',
        'outline-secondary':
          'border-transparent bg-secondary-8 text-secondary-500',
        'outline-primary': 'border-transparent bg-primary-10 text-primary-400',
        'outline-destructive':
          'border-transparent bg-destructive-50 text-destructive-500',
        warn: 'border-transparent bg-warn-500 text-white',
        neutral: 'border-transparent bg-primary-10 text-primary-300',
        secondary: 'border-transparent bg-secondary-400 text-white',
        destructive: 'border-transparent bg-destructive-500 text-white',
        success: 'border-transparent bg-success-500 text-white',
      },
    },
    defaultVariants: {
      variant: 'neutral',
    },
  },
);

export type Variants = VariantProps<typeof statusTagVariants>['variant'];

export interface StatusTagProps
  extends PropsWithChildren,
    VariantProps<typeof statusTagVariants> {
  className?: string;
  size?: 'small' | 'normal';
}

const StatusTag = ({
  variant,
  className,
  children,
  size = 'normal',
}: StatusTagProps) => {
  return (
    <div
      className={cn(statusTagVariants({ variant }), className, {
        'italic text-xs px-2.5 py-0.5': size === 'small',
      })}
    >
      {children}
    </div>
  );
};

export default StatusTag;
